import { MyGeneralSettings } from '@myneva-portals/models/src/models/my-organization/my-general-settings';
import { ErrorTimeout, LoadingWrapper } from 'front-components/src/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { sessionSelector } from '../../state/reducers/session';
import { UserSettingsProps } from './user-settings.props';
import {
  ContentBar,
  UserSettingsOverviewContent,
} from '@myneva-portals/projects/src/components';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { navigate } from 'gatsby';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { restSettingsService } from '../../services/settings-service/rest-settings-service';

export const UserSettings: React.FC<UserSettingsProps> = (
  props: UserSettingsProps
) => {
  const [content, setContent] = React.useState<JSX.Element>(null);
  const { t } = useTranslation();
  const { userUuid } = useSelector(sessionSelector);
  const { breadcrumbs } = useSelector(navigationSelector);

  const { isLoading, error, data, refetch } = useQuery(
    'getMySettingsQuery',
    () => {
      return restSettingsService
        .getMySettings(userUuid)
        .then((data: MyGeneralSettings) => data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setContent(getModuleContent(isLoading, data, error, userUuid));
    }
  }, [data]);

  const getModuleContent = (
    isLoading: boolean,
    data: MyGeneralSettings,
    error: unknown,
    uuid: string
  ) => {
    if (!isLoading) {
      return error ? (
        <div>error</div>
      ) : (
        <>
          <ContentBar
            translation={t}
            breadcrumbsItems={breadcrumbsUtils.getBreadcrumbs(breadcrumbs)}
            actionPanelContent={<></>}
          />
          <UserSettingsOverviewContent
            {...data}
            uuid={uuid}
            translation={t}
            onEdit={() =>
              navigate(PageKey.USER_SETTINGS_GENERAL, {
                state: {
                  isEdit: true,
                  backLocation: PageKey.USER_SETTINGS_OVERVIEW,
                },
              })
            }
          />
        </>
      );
    }
  };

  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <div>
      <LoadingWrapper
        isLoading={isLoading}
        content={error ? errorComponent : content}
        useLineProgress={true}
      />
    </div>
  );
};
